<template>
    <div class="con">
        <div class="top_box">
            <div class="top_box_img">
                <img :src="baseData.intro_img" alt="">
            </div>
            <div class="top_box_info">
                <div class="info_title">
                    <img :src="baseData.icon" alt="">
                    <h1>{{ baseData.name }}</h1>
                </div>
                <ul>
                    <li>版本：{{ baseData.version }}</li>
                    <li>更新时间：{{ baseData.uu_time }}</li>
                    <li>系统平台：{{ baseData.platform }}</li>
                </ul>
                <div style="bottom: 0px;" class="download-btn">
                    <img src="../assets/download_ico.png">
                    <span style="letter-spacing:2px;" @click="downLoad">立即下载</span>
                </div>
            </div>
        </div>
        <div class="main_box">
            <div class="title">软件介绍</div>
            <div class="content">
                <p>{{ baseData.introduce }}</p>
            </div>
            <div class="title" v-if="baseData.video_url">软件视频</div>
            <video style="display: block;width: 750px;margin: 30px auto" v-if="baseData.video_url" :src="baseData.video_url" controls :poster="baseData.intro_img"></video>
            <div class="title">软件截图</div>
            <div class="content_img">
                <img v-for="(item,index) in baseData.img" :src="item" alt="">
            </div>
            <div class="title" v-if="baseData.item!=''">软件版本</div>
            <div class="version" v-if="baseData.item!=''">
                <div v-for="(item,index) in baseData.item" class="version-item">
                    <div class="version-item-l">
                        <img :src="baseData.icon" alt="">
                        <p>{{ item.name }}</p>
                    </div>
                    <div class="version-item-r">
                        <div class="foot_btn">
                            <img src="../assets/download_ico.png">
                            <span style="letter-spacing:2px;" @click="downLoad">立即下载</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="foot_bottom">
            <p>版权所有</p>
            <p>本网站的图文和软件来自于网络，我们尊重他人的合法权益，如有内容侵犯您的合法权益，请及时与我们联系，我们将第一时间安排删除。</p>
        </div>
        <div style="width: 100%;height: 80px"></div>
        <div class="foot">
            <div class="foot_title">
                <img :src="baseData.icon" alt="">
                <h2 style="color: black;font-weight: 400">{{ baseData.name }}</h2>
            </div>
            <div class="foot_btn">
                <img src="../assets/download_ico.png">
                <span style="letter-spacing:2px;" @click="downLoad">立即下载</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Home",
        data() {
            return {
                baseData: [],
                bid: ""
            }
        },
        created(){
            const aa = this.$route.query.bd_vid;
            const name = this.$route.query.name;
            if(aa!=undefined){
                this.bid = aa;
            }
            this.getData(name);
        },
        methods: {
            async getData(name){
                const res = await this.request.get("/api/home?name=" + name);
                // console.log(res);
                if(res.code == 1){
                    await this.addUser(name);//增加统计网站的访客数量
                    this.baseData = res.data;
                }
            },
            async addUser(name){
                const data = {
                    name: name,
                    bid: this.bid,
                    ua: navigator.userAgent,
                };
                const res = await this.request.post("/api/add_user",data);
            },
            downLoad() {
                if(this.baseData != ""){
                    const downUrl = this.baseData.down_url;
                    window.location.href = downUrl;
                }
            }
        }
    }
</script>

<style scoped>
    .top_box{
        width: 100%;
        height: 520px;
        background: #3881C6 url(../assets/bg_t.png) center center no-repeat;
        /*background-position: cover;*/
        color: #fff;
        position: relative;
        padding: 100px 80px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
    }

    .top_box_img{
        flex: 1;
        /*margin-left: 7%;*/
        text-align: right;
    }

    .top_box_img img{
        width: 540px;
        height: 100%;
        border-radius: 10px;
        background: white;
    }

    .top_box_info{
        flex: 1;
        position: relative;
        margin-left: 120px;
        margin-top: 20px;
    }

    .info_title{
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .info_title img{
        width: 45px;
        border-radius: 7px;
        margin-right: 10px;
    }

    .info_title h1{
        color: antiquewhite;
        font-size: larger;
        letter-spacing: 1px;
        font-size: 36px;
    }

    .top_box_info ul{
        list-style: none;
        padding: 0 0;
        margin: 0 0;
        margin-top: 35px;
        line-height: 20px;
    }

    .top_box_info ul li{
        margin-bottom: 12px;
        color: #fff;
    }

    .download-btn{
        display: inline-block;
        background-color: #1cba53;
        color: #fff;
        width: 230px;
        height: 65px;
        line-height: 60px;
        text-align: center;
        font-size: 30px;
        border-radius: 10px;
        cursor: pointer;
        position: absolute;
        bottom: 5px;
    }

    .download-btn img{
        vertical-align: middle;
        margin-right: 8px;
    }

    .download-btn span{
        vertical-align: middle;
        letter-spacing: 2px;
    }

    .main_box{
        width: 1200px;
        max-width: 100%;
        padding: 0 15px;
        margin: 0 auto;
    }

    .title{
        font-size: 25px;
        font-weight: 600;
        line-height: 28px;
        border-left: 7px solid #1cba53;
        padding: 0 15px;
        margin: 45px 0 15px 0;
    }

    .content{
        color: #787a7c;
        padding: 20px;
    }

    .content_img{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

    .content_img img{
        width: 350px;
        height: 250px;
        margin: 0 15px;
    }

    .version{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 40px;
    }

    .version-item{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 48%;
        height: 120px;
        box-shadow: 0px 4px 20px 0px rgba(0, 27, 48, 0.15);
        border-radius: 10px;
        margin-bottom: 40px;
    }

    .version-item-l{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        margin-left: 30px;
    }

    .version-item-l img{
        width: 60px;
    }

    .version-item-l p{
        font-size: 20px;
        margin-left: 15px;
    }

    .version-item-r{
        margin-right: 30px;
    }

    .foot_bottom{
        padding: 25px 0;
        color: gray;
        margin-top: 50px;
        border-top: 1px solid rgb(232 232 232 / 50%);
    }

    .foot_bottom p{
        text-align: center;
        font-size: 12px;
        line-height: 20px;
    }

    .foot{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #fff;
        box-shadow: 0px 0 10px #ccc;
    }

    .foot_title{
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .foot_title img{
        width: 45px;
        border-radius: 7px;
        margin-right: 10px;
    }

    .foot_btn{
        background-color: #1cba53;
        color: #fff;
        width: 160px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        font-size: 20px;
        border-radius: 10px;
        cursor: pointer;
    }

    .foot_btn img{
        vertical-align: middle;
        margin-right: 8px;
        width: 25px;
    }

    .foot_btn span{
        vertical-align: middle;
        letter-spacing: 2px;
    }
</style>